@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css");

$mainAnimationTimeFast: 0.1s;
$mainAnimationTime: 0.5s;
$mainColor: #dc143c;
$mainColorHover: rgb(175, 29, 59);
$mainBackgroundColor: rgba(241, 241, 241, 0.863);

$NAVBAR_HEIGHT: 60px;

$DEFAULT_CONTENT_MARGIN: 30px 10%;

.btn {
    background-color: $mainColor;
    color: white;
    border-width: 0px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    transition: all $mainAnimationTime;
    border-radius: 10px;
    padding: 5px 10px;

}
.btn:hover {
    background-color: $mainColorHover;
}

.btn-animation {
    transition: all $mainAnimationTimeFast;
    cursor: pointer;
    &:hover {
        transform: scale(1.1);
    }
}

.rounded-corner-sr {
    border-radius: 5px;
}


.button-style {
    background-color: crimson;
    color: white;
    height: 40px;
    margin: 2px 5px;
    outline: 0;
    border-radius: 10px;
    border-width: 0;
    padding: 0 10px;
    position: relative;
    // Center text
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.success {
    background-color: lightgreen !important;
    color: black !important;
    &:hover {
        background-color: darkgreen !important;
        color: white !important;
    }
}
.form-input {
    height: 33px;
    border-radius: 10px;
    font-size: 15px;
    width: 30px;
    transition: all $mainAnimationTime;
    text-align: center;
}

$width: 1200px;

@mixin mobile {
    @media (max-width: #{$width}) {
        @content;
    }
}

.input-number-hide-arrow::-webkit-outer-spin-button,
.input-number-hide-arrow::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.input-number-hide-arrow[type="number"] {
    -moz-appearance: textfield;
}

.green-color {
    color: rgb(0, 109, 5) !important;
}

.profit-icon{
    margin-left: 10px;
}

.loss-color {
    color: rgb(175, 29, 59) !important;
}

.hover-animation-default {
    transition: all $mainAnimationTimeFast;
    cursor: pointer;
    &:hover {
        transform: scale(1.1);
    }
}

.unhide {
    opacity: 1 !important;
}

.hide {
    opacity: 0 !important;
    visibility: hidden !important;
    pointer-events: none !important;
}

.no-input-style {
    background-color: transparent;
    color: black;
    border-width: 0;
    outline: 0;
    &:hover {
        background-color: transparent;
        color: black;
    }
}

.input_text {
    // Remove default input style
    background-color: transparent;
    color: black;
    border-width: 0;
    outline: 0;
    transition: all $mainAnimationTime;
    width: 175px;
    &:hover {
        background-color: transparent;
        color: black;
    }
    // Underline style
    border-bottom: 1px solid black;
    &:focus {
        border-bottom: 1px solid $mainColor;
    }
    &:hover {
        border-bottom: 1px solid $mainColor;
    }
    // background-color: antiquewhite;
}