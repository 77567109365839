@import "../../globalcss.scss";

.picklist {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    overflow: auto;

    .content-container {
        display: flex;
        flex-direction: column;
        width: 80%;

        .search-bar {
            display: flex;

            width: 100%;
            justify-content: center;
            align-items: center;
            margin-top: 30px;

            .action-type1 {
                display: flex;
                align-items: center;

                .title {
                    font-size: 20px;
                    font-weight: bold;
                    margin-left: 20px;
                }
                margin-right: 10px;
            }

            .export-icon {
                color: rgb(0, 155, 0);
                font-size: 45px;
                cursor: pointer;
                transition: all $mainAnimationTimeFast ease-in-out;
            }
            
            .print-icon {
                color: rgb(27, 45, 163);
                font-size: 45px;
                cursor: pointer;
                transition: all $mainAnimationTimeFast ease-in-out;
            }

            .print-icon:hover {
                color: rgb(16, 0, 155);
            }

            

            .export-icon:hover {
                // Dark green
                color: rgb(0, 128, 0);
            }

            input {
                width: 100%;
                height: 40px;
                font-size: 20px;
                margin: 20px 0px;
            }

            .refresh-icon {
                font-size: 27px;
                background-color: $mainColor;
                padding: 9px;
                border-radius: 50%;
                color: white;
                cursor: pointer;
                transition: all $mainAnimationTime ease-in-out;
                margin-left: 10px;
            }

            .refresh-icon:hover {
                background-color: $mainColorHover;
            }
            .hastag-icon {
                font-size: 27px;
                // Purple color
                background-color: rgb(128, 0, 128);
                padding: 9px;
                border-radius: 50%;
                color: white;
                cursor: pointer;
                transition: all $mainAnimationTime ease-in-out;
                margin-left: 10px;
            }

            .hastag-icon:hover {
                background-color: rgb(78, 0, 78);
            }
        }

        .orders-list {
            width: 100%;

            table {
                width: 100%;

                thead {
                    width: 100%;

                    tr {
                        th {
                            font-size: 20px;
                            padding: 10px;
                            border-bottom: black solid 1px;

                        }
                    }
                }

                tbody {
                    width: 100%;

                    tr {
                        td {
                            font-size: 17px;
                            // padding: 10px;
                            border-bottom: black solid 1px;

                            .platform-logo {
                                width: 30px;
                                height: 30px;
                            }

                            .product-image-wrapper {
                                display: flex;
                                flex-wrap: wrap;
                            }
                        }
                    }
                }
            }
        }
    }

    .announce {
        position: absolute;
        // Center the screen
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .refresh-button {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgb(82, 82, 82);
            color: white;
            font-size: 20px;
            cursor: pointer;
            border-radius: 5px;
            height: 50px;
            margin-top: 20px;
        }
    }
}
.div-hidden {
    display: none;
}