@import './Text.scss';

.input-text-regular {
    width: 100%;
    // height: 40px;
    border: 1px solid #ccc;
    border-radius: 2.5px;
    padding: 3px 10px;
    outline: none;

    &:focus {
        border-color: #000;
    }
}

.input-text-no-style {
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
    padding: 0;
    margin: 0;
    color: #000;
    // Remove arrow from number input
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    
}