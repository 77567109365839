table {
    width: 100%;

    thead {
        tr {
            th {
                padding: 10px;
                border-bottom: black solid 1px;
            }
        }
    }
    tbody {
        tr {
            td {
                padding: 10px;
                border-bottom: black solid 1px;
                .platform-logo {
                    width: 30px;
                    height: 30px;
                }
                .product-image-wrapper {
                    display: flex;
                }
            }
        }
    }
}

.sticky {
    overflow: scroll;
    .header {
        position: sticky;
        z-index: 1;
        width: fit-content;
    }

    .header {
        top: 0;
        background-color: #ffffff;
    }

    .body {
        position: relative;
        z-index: 0;
    }

}
