
.packing-list-to-print {

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 500px;
    overflow: scroll;

    .packing-list-header {
        margin-top: 50px;
        margin-left: auto;
        margin-right: auto;
    }

    .packing-list-content {
        width: 100%;
        margin-top: 50px;
        .packing-list-content-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;

            .packing-list-item {
                margin-left: auto;
                margin-right: auto;
                padding-top: 10px;
                padding-bottom: 10px;
                width: 80%;
                // Underline
                border-bottom: 1px solid black;
                .packing-list-item-wrapper {
                    width: 100%;
                    display: flex;
                    .product-image {
                        flex:2;
                        img {
                            width: 75px;
                        }
                    }
                    .code {
                        flex: 2;
                    }
                    .name {
                        flex: 4;
                    }
                    .attr {
                        flex: 1;
                    }
                    .qty {
                        flex: 1;
                    }

                }
            }
        }
    }
}