@import "../../globalcss.scss";

.blogs {
    height: calc(100vh - #{$NAVBAR_HEIGHT});
    .blogsContent {
        margin: $DEFAULT_CONTENT_MARGIN;
        justify-content: center;
        font-weight: bold;

        .header {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .action {
            }
        }
        .body {
            .body_blogs {
                display: flex;
                flex-wrap: wrap;
                .blog_wrapper{
                    margin-right: 20px;
                    margin-bottom: 20px;
                }

                .title {
                    margin-top: 50px;
                }
            }
        }
    }
}

.blog_html {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // background-color: rgb(71, 169, 169);
    width: 100%;
    word-wrap: break-word;
    word-break: break-all;
    text-align: left;
    * {
        word-wrap: break-word;
        word-break: break-all;
        // Word on the left
        text-align: left;
    }
    .bold {
        font-weight: bold;
    }
    // Remove border on focus
    *:focus {
        outline: none;
    }
    img {
        max-width: 500px;
        align-self: center;
        transition: all $mainAnimationTimeFast;
        margin-top: 30px;
        margin-bottom: 30px;
         
        &:hover {
            transform: scale(1.1);
        }
    }
}
