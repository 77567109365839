@import '../../../globalcss.scss';

.store-list-table-sub-component-store-list-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 1000px;

    .action-bar {
        display: flex;

        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 30px;

        .action-type1 {
            display: flex;
            align-items: center;

            .clear-icon {
                font-size: 27px;
                background-color: $mainColor;
                padding: 9px;
                border-radius: 50%;
                color: white;
                cursor: pointer;
                transition: all $mainAnimationTime ease-in-out;
                margin-left: 10px;
            }

            .title {
                font-size: 20px;
                font-weight: bold;
                margin-left: 20px;
            }

            margin-right: 10px;
        }
    }

    .store-list-header {
        margin-top: 50px;
        margin-left: auto;
        margin-right: auto;
    }

    .store-list-content {
        width: 100%;
        margin-top: 50px;

        .store-list-content-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;

            .store-list-item {
                margin-left: auto;
                margin-right: auto;
                padding-top: 10px;
                padding-bottom: 10px;
                width: 80%;
                // Underline
                border-bottom: 1px solid black;

                .store-list-item-wrapper {
                    width: 100%;
                    display: flex;

                    .product-image {
                        flex: 2;

                        img {
                            width: 75px;
                        }
                    }

                    .code {
                        flex: 2;
                    }

                    .name {
                        flex: 4;
                    }

                    .attr {
                        flex: 1;
                    }

                    .qty {
                        flex: 1;
                    }

                }
            }
        }
    }
}