@import "../../globalcss.scss";

.navBar-wrapper {

    .NavBar {
        display: flex;
        background-color: crimson;
        align-items: center;
        height: $NAVBAR_HEIGHT;
        min-width: 900px;
        width: 100%;
        position: relative;
        overflow-x: scroll;
        overflow-y: hidden;
        min-width: 0;

        // Hide the scrollbar
        &::-webkit-scrollbar {
            display: none;
        }

        .Left {
            display: flex;
            align-items: center;
            height: 100%;
            width: 100%;

            .logo {
                margin-left: 30px;
                height: 50px;
                width: 50px;

                img {
                    height: 100%;
                    box-shadow: 2px 2px 2px #000;
                }
            }

            .menuList {
                display: flex;
                height: 100%;
                overflow-x: scroll;

                li {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    height: 100%;
                    cursor: pointer;
                    transition: all $mainAnimationTime;

                    .MenuLink {
                        height: 100%;
                        width: 100%;
                        display: flex;
                        padding: 0 20px;
                        font-size: 10px;
                        align-items: center;
                        justify-content: center;
                        text-decoration: none;
                        color: white;
                    }
                }

                li:hover,
                .active {
                    background-color: $mainColorHover;
                }
            }

            // Hide all scroll bar
            ::-webkit-scrollbar {
                display: none;
            }
        }

        .right {
            display: flex;
            // align-items: center;
            flex-direction: column;
            justify-content: flex-start;
            color: white;
            margin-right: 10px;
            // background-color: aqua;
            align-items: flex-end;

            .header {
                margin-top: -3px;
                margin-bottom: -3px;
                display: flex;

                .greeting {
                    margin-right: 10px;
                }

            }

            .action {
                display: flex;
                cursor: pointer;

                * {
                    transition: all $mainAnimationTimeFast;
                    margin-left: 10px;
                }

                *:hover {
                    transform: scale(1.2);
                }
            }
        }
    }

    .action-popup {
        position: absolute;
        right: 10px;
        top: calc($NAVBAR_HEIGHT + 10px);
        transition: all $mainAnimationTimeFast;
    }
}