@import '../globalcss.scss';

.delete-icon {
    // font-size: 30px;
    color: $mainColor;
    cursor: pointer;
    transition: all 0.2s ease;
    &:hover {
        color: $mainColorHover;
        transform: scale(1.3);
    }
}