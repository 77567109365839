body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Circular';
  src: local('Circular') url(./fonts/Circular/Circular.ttf) format('truetype');
}

@font-face {
  font-family: 'Baijam';
  src: url(./fonts/11-TH-Baijam/TH\ Baijam.ttf) format('truetype');
}
@font-face {
  font-family: 'Baijam-bold';
  src: url(./fonts/11-TH-Baijam/TH\ Baijam\ Bold.ttf) format('truetype');
}

@import url('https://fonts.googleapis.com/css2?family=Anuphan:wght@100;200;300;400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100;200;300;400;500;600;700;800;900&display=swap');