@import '../../../../globalcss.scss';
@import "../../../../styles/Text.scss";

.receipt_detail {
    .receipt_detail__content {
        margin: $DEFAULT_CONTENT_MARGIN;
        .order-detail {
            .header-table {
                display:flex;
                flex-direction: column;
                span {
                    font-size: $SMALL_FONT_SIZE;
                    font-weight: bold;
                }
            }
        }
        .header {
            display: flex;
            width: 100%;
            // Start from right
            justify-content: space-between;
            align-items: center;
            
        }
        .info {
            .item {
                display: flex;
                width: 100%;
                justify-content: space-between;
                // Center the text
                align-items: center;
                margin-top:10px;

                .label {
                    flex:2;
                }
                .input_text {
                    margin-left: 10px;
                    flex:14;
                }
                .input_checkbox {
                    flex:14;
                    display: flex;
                    .checkbox-group {
                        display: flex;
                        margin-right: 20px;
                    }
                }
            }

        }
    }
}

.receipe {
    width: 21cm;
    // height: 29.5cm;
    .receipt-wrapper {
        display: flex;
        flex-direction: column;
        margin: 0px 5%;
        .receipe-header {
            margin-top:30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .shop-detail {
                font-size: $SMALL_FONT_SIZE;
            }
            .receipt-title {
                span {
                    font-size: $DEFAULT_FONT_SIZE;
                }
                font-weight: bold;
                margin-top: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }
            .invoice-stamp {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                flex-direction: column;
                width: 100%;
                margin-top: 20px;
                span {
                    font-size: $SMALL_FONT_SIZE;
                }
            }
        }
        .receipt-content {
            display: flex;
            flex-direction: column;
            .customer-detail {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                span {
                    font-size: $SMALL_FONT_SIZE;
                }
            }
            .order-item-list {
                font-size: $SMALL_FONT_SIZE;
                .header-table {
                    display:flex;
                    flex-direction: column;
                    span {
                        font-size: $SMALL_FONT_SIZE;
                        font-weight: bold;
                    }
                }
                .flex-table {
                    .header {
                        // padding: 0px;
                        *{
                            text-align: left;
                        }
                    }
                    .body {
                        font-size: $SMALL_FONT_SIZE;
                        * {
                            // Align the text to left
                            text-align: left;

                        }
                        // padding:0;
                        padding-top:20px;
                    }
                }
            }
        }
        .receipt-footer {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            .sign-area {
                margin-top: 20px;
                display:flex;
                flex-direction: column;
                align-items: flex-end;

                span {
                    font-size: $SMALL_FONT_SIZE;
                }
                
            }


            .sign-space {
                margin-top: 30px;
            }
        }
    }
}