@import '../../globalcss.scss';

.receipt {
    .receipt__content {
        .title {
            
        }
        .body {
            .group1 {
                margin-bottom: 30px;
                .content {
                     .item {
                        display: flex;
                        flex-direction: column;
                        width:100vw;
                        align-items: center;
                        .item__input{
                            display: flex;
                            align-items: center;
                            transition: all $mainAnimationTimeFast;
                            
                            &:hover {
                                transform: scale(1.1);
                            }
                            .submit-btn {
                                cursor: pointer;
                                &:hover {
                                    color: $mainColor;
                                }
                            }
                        }
                     }
                }
            }
        }
    }
}