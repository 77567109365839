
$EXTRA_SMALL_FONT_SIZE: 0.68rem;
$SMALL_FONT_SIZE: 0.8rem;
$DEFAULT_FONT_SIZE: 1rem;
$BIG_FONT_SIZE: 1.6rem;

.extra-small-font {
    font-size: $EXTRA_SMALL_FONT_SIZE;
}

.small-font {
    font-size: $SMALL_FONT_SIZE;
}

.default-font{
    font-size: $DEFAULT_FONT_SIZE;
}

.big-font{
    font-size: $BIG_FONT_SIZE;
}



.text-underlined {
    text-decoration: underline;
}