@import '../../globalcss.scss';


.bot-demo-page {
    background-color: $mainColor;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Noto Sans Thai', sans-serif !important;


    .bot-demo-container {
        background-color: white;
        width: 60%;
        height: 80%;
        border-radius: 25px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
        display: flex;
        font-family: 'Noto Sans Thai', sans-serif !important;

        .bot-demo-left {
            flex: 3;
            // background-color: aqua;
            align-items: center;
            justify-content: center;
            display: flex;
            font-family: 'Noto Sans Thai', sans-serif !important;

            .container {
                flex-direction: column;
                display: flex;
                height: 95%;
                width: 90%;
                font-family: 'Noto Sans Thai', sans-serif !important;

                .title {
                    display: flex;
                    margin-bottom: 20px;
                    align-items: center;
                    justify-content: space-between;
                    font-family: 'Noto Sans Thai', sans-serif !important;

                    .logo {
                        img {
                            width: 50px;
                            height: 50px;
                            border-radius: 10%;
                        }
                    }

                    .name {
                        font-size: 20px;
                        font-weight: 600;
                        font-family: 'Noto Sans Thai', sans-serif !important;

                    }
                }

                .chat-history {
                    flex: 1;
                    overflow: scroll;
                    font-family: 'Noto Sans Thai', sans-serif !important;

                    // Hide the scrollbar
                    &::-webkit-scrollbar {
                        display: none;
                    }
                }

                .user-action {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    height: 40px;
                    font-family: 'Noto Sans Thai', sans-serif !important;

                    // Remove the default input style
                    input {
                        margin-left: 20px;
                        margin-right: 20px;
                        border: none;
                        outline: none;
                        width: 100%;
                        height: 100%;
                        padding: 0 10px;
                        // Underline
                        border-bottom: 1px solid #e5e5e5;
                        font-family: 'Noto Sans Thai', sans-serif !important;
                        font-size: 16px;

                    }

                    .input-wrapper-input-icon {
                        cursor: pointer;
                        font-size: 16px;
                        i {
                            font-size: 18px;
                        }
                    }
                }
            }
        }

        .bot-demo-right {
            flex: 1;
            border-left: 1px solid #e5e5e5;
            align-items: center;
            justify-content: center;
            display: flex;
            font-family: 'Noto Sans Thai', sans-serif !important;

            .container {
                flex-direction: column;
                display: flex;
                height: 95%;
                width: 90%;
                overflow: scroll;
                font-family: 'Noto Sans Thai', sans-serif !important;

                // Hide the scrollbar
                &::-webkit-scrollbar {
                    display: none;
                }

                .title {
                    margin-bottom: 20px;
                    align-items: center;
                    justify-content: space-between;
                    align-self: flex-end;
                    font-family: 'Noto Sans Thai', sans-serif !important;
                    
                    .logo {
                        img {
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                        }
                    }
                    
                    .name {
                        display: flex;
                        align-items: center;
                        height: 50px;
                        font-size: 20px;
                        font-weight: 600;
                        font-family: 'Noto Sans Thai', sans-serif !important;
                    }
                }

                .knowledge-list {
                    .knowledge-item {
                        margin-bottom: 20px;

                        .knowledge-item-name {
                            font-size: 16px;
                            font-weight: 600;
                            text-align: left;
                            font-family: 'Noto Sans Thai', sans-serif !important;
                            font-size: 16px;
                        }

                        .knowledge-item-instruction {
                            text-align: left;
                            font-family: 'Noto Sans Thai', sans-serif !important;
                            font-size: 16px;

                        }

                        .knowledge-item-information {
                            text-align: left;
                            font-family: 'Noto Sans Thai', sans-serif !important;
                            font-size: 16px;

                        }
                    }
                }
            }
        }
    }
}