@import '../../globalcss.scss';
@import '../../scss/Animation.scss';

.pages-form-submitted {
    // Center the login page
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;

    .pages-form-submitted-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80%;
        width: 80%;
        @include mobile {
            flex-direction: column-reverse;
            width: 100%;
        }

        .panel {
            display: flex;
            flex-direction: column;
            max-width: 500px;
            flex:1;
            
            @include mobile {
                justify-content: center;
                align-items: center;
                width: 100%;
                
            }

            .title {
                font-size: 2rem;
                font-weight: 600;
                margin-bottom: 1rem;
            }

            .form {
                display: flex;
                flex-direction: column;

                @include mobile {
                    width: 80%;
                }

                input {
                    // Remove the default styling
                    border: none;
                    border-bottom: 1px solid #ccc;
                    padding: 0.5rem 0;
                    margin-bottom: 1rem;
                    font-size: 1rem;
                    outline: none;

                }
            }

            .button {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 1rem;

                @include mobile {
                    flex-direction: column;
                    gap: 1rem;
                }

                button {
                    border: none;
                    font-size: 1rem;
                    width: 200px;
                    height: 50px;
                    border-radius: 25px;
                    cursor: pointer;
                    outline: none;
                    transition: $FAST_ANIMATION;
                    box-shadow: 0px 2px 3px rgba(0, 0, 0, 1);
                    font-weight: 600;
                    // Move the text to the center
                    display: flex;
                    justify-content: center;
                    align-items: center;


                    &:hover {
                        // Scale the button on hover
                        transform: scale(1.05);
                    }
                }

                .login-button {
                    background-color: #ecb125;

                    &:hover {
                        background-color: darken(#ecb125, 3%);
                    }
                }

                .register-button {
                    background-color: #fbfbfb;

                    &:hover {
                        background-color: darken(#fbfbfb, 3%);
                    }
                }
            }
        }

        .logo {
            max-width: 400px;
            flex:1;

            img {
                width: 100%;
            }
            
            @include mobile {
                img {
                    width: 100%;
                }
            }
        }
    }



}