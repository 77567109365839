.blogs_floater {
    width: 300px;
    height: 200px;
    display: flex;
    flex-direction: column;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .title {
        position: absolute;
        color: white;
        text-shadow: 1px 2px 3px rgb(97, 97, 97);
        bottom: 0;
        left: 3%;
    }
    .create_time {
        position: absolute;
        bottom: 0;
        right: 3%;
        color: white;
        text-shadow: 1px 2px 3px rgb(97, 97, 97);
    }
}
