@import '../../../../globalcss.scss';

.receiptcheck{
    .content {
        margin: $DEFAULT_CONTENT_MARGIN;
        .receipt-copy{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;

        }

    }
}