.chat-history-sub-component-right-panel-component {
    display: flex;
    flex-direction: column;

}

.chat-history-sub-component-right-panel-component-chat-item {
    margin-top: 10px;
    .chat-item-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        font-family: 'Noto Sans Thai', sans-serif !important;

        .chat-item-wrapper-header {
            display: flex;
            align-items: center;
            font-family: 'Noto Sans Thai', sans-serif !important;

            .chat-item-wrapper-header-avatar {
                img {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }

            .chat-item-wrapper-header-name {
                margin-left: 10px;
                font-size: 16px;
                font-family: 'Noto Sans Thai', sans-serif !important;

            }
        }
        .chat-item-wrapper-content {
            .chat-item-wrapper-content-message {
                white-space: pre-wrap;
                font-family: 'Noto Sans Thai', sans-serif !important;
                font-size: 16px;
                * {
                    font-family: 'Noto Sans Thai', sans-serif !important;
                    font-size: 16px;
                }
            }

            .chat-item-wrapper-content-message-code {
                padding-left: 3%;
                padding-right: 3%;
            }
        }
    }
    .chat-item-wrapper-me {
        align-items: flex-end;
        text-align: right;
    }
}