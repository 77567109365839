@import "../../Blogs.scss";
@import "../../../../globalcss.scss";
@import "../../../../styles/Text.scss";

.blogdetail {
    height: calc(100vh - #{$NAVBAR_HEIGHT});
    .blogdetail_content {
        margin: $DEFAULT_CONTENT_MARGIN;
        .blogdetail_content_detail {
            display: flex;
            flex-direction: column;
            .title {
                width: 100%;
                border-bottom: 1px solid rgb(184, 184, 184);
                font-size: $BIG_FONT_SIZE;
                font-weight: bold;
                justify-self: start;
                align-self: start;
                position: relative;

                .back_arrow {
                    position:absolute;
                    left: 0px;
                    top:25%;
                }
            }
            .body {
                margin-top: 50px;
            }
        }
    }
}
