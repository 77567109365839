@import "../../globalcss.scss";

.wholesale{
    width: 100%;
    height: calc(100vh  - $NAVBAR_HEIGHT);

    .content-container{
        height: 95%;
        width: 85%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        .content-header{

            .wholesale-title {
                margin-top: 20px;
                margin-bottom: 20px;
            }

            @include mobile{
                h2 {
                    font-size: 30px;
                }
            }
            .search-bar {
                display: flex;
                align-items: center;
                .search-icon {
                    font-size: 23px;
                }
        
                .search-input {
                    margin-left: 10px;
                    // Remove the default input border
                    width: 200px;
                    border: none;
                    border-bottom: 1px solid rgb(155, 155, 155);
                    // font-family: 'Baijam';
                }
                // Config placeholder
                .search-input::-webkit-input-placeholder {
                    // font-family: 'Baijam';
                }
            }
        }
        .content-wrapper {
            height: 100%;
            min-height: 0px;
            width: 100%;
            overflow-x: scroll;

            // Hide the scrollbar
            &::-webkit-scrollbar {
                display: none;
            }
        }

        .content-footer {
        }

    }
    .attr {
    }
    .product-image{
        width: 80px;
        height: 80px;
        transition: all 0.2s ease;
        &:hover{
            transform: scale(3);
        }
    }
    
    .name{
        margin-left: 10px;
    }
    
    .product-name{
        display: flex;
        align-items: center;
    }
}