
@import '../../../../../globalcss.scss';

.always-lowest-prices-form {
    .content {
        margin: $DEFAULT_CONTENT_MARGIN;

        .body {
            .item {
                display: flex;
                align-items: center;
                margin-top: 10px;
                
                .label {
                    flex:2;
                    transform: translate(0, 10%);
                }
                input {
                    margin-left: 10px;
                    flex: 8;
                }
                button {
                    margin-left: 50px;
                }
            }
        }
    }
}