@import "../../../../globalcss.scss";
@import "../../Blogs.scss";
@import "../../../../styles/Text.scss";

.new_blog {
    height: 100vh;
    .new_blog_content {
        margin-top: 30px;
        margin-left: 5%;
        margin-right: 5%;
        display: flex;
        flex-direction: column;
        height: calc(100vh - $NAVBAR_HEIGHT - 80px);
        // justify-content: space-around;

        .header {
            flex:1;
            display: flex;
            justify-content: space-between;
            .title {
                font-weight: bold;
            }
            .action {
                display: flex;
                .btn {
                    margin-left: 10px;
                }
            }
        }
        .body {
            flex:1000;
            display: flex;
            min-height: 0;
            // Hide scroll bar except on hover
            ::-webkit-scrollbar {
                width: 0px;
                background: none;
            }
            

            .blog_action {
                display: flex;
                flex-direction: column;
                margin-top: 5px;
                * {
                    margin: 5px 0px;
                }
            }
            .body_left {
                flex: 1;
                overflow: scroll;
                .blog_title {
                    display: flex;
                    .title_input {
                        border-bottom: 1px solid rgb(184, 184, 184);
                        font-size: $BIG_FONT_SIZE;
                        font-weight: bold;
                    }
                }
                .blog_body {
                }
            }
            .body_right {
                flex: 1;
                overflow: scroll;
                .blog_preview_title {
                    display: flex;
                    border-bottom: 1px solid rgb(184, 184, 184);
                    font-size: $BIG_FONT_SIZE;
                    font-weight: bold;
                }
            }
        }
        .footer {
            flex:1;
            display: flex;
            .cover_image {
                display: flex;
                flex-direction: column;
                .cover_image_title {
                }
                
                .cover_image_body {
                    width: 150px;
                    height: 100px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            .images {
                display: flex;
                flex-direction: column;
                margin-left: 20px;
                min-width: 0;
                width: 100%;
                .cover_image_title{
                    width: 100%;
                }
                .cover_image_body {
                    width: 100%;
                    height: 100px;
                    display: flex;
                    overflow: scroll;
                    // Hide scrollbar
                    -ms-overflow-style: none;  /* IE and Edge */
                    scrollbar-width: none;  /* Firefox */
                    &::-webkit-scrollbar {
                        display: none;
                    }
                    img {
                        width: 150px;
                        height: 100%;
                        object-fit: cover;
                        cursor: pointer;
                        transition: all $mainAnimationTimeFast;
                        margin-left: 10px;
                        &:first-child {
                            margin-left: 0px;
                        }
                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
    }
}
