.store-list-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;

    .container {
        display: flex;
        flex-direction: column;
        width: 80%;
    }
}