@import "../../../globalcss.scss";
@import "../../../styles/Text.scss";

.login_popup {
    background-color: white;
    // Drop shadow
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    padding: 10px;
    // Round corner
    border-radius: 10px;
    * {
        font-size: $SMALL_FONT_SIZE !important;
    }

    .login_popup_action {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 10px;
        .login_popup_action_button {
            background-color: $mainColor;
            color: white;
            padding: 5px 10px;
            border-radius: 10px;
        }
    }
}
