@import "./globalcss.scss";
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css");


.App {
  text-align: center;
}

* {
  font-family: 'Noto Sans Thai', sans-serif;
}
