.sub-page-self-quotation-create-sub-container-customer-form {
    .content-p1 {
        display: flex;
        flex-direction: column;
        margin-right: 20px;

        .title {
            align-self: flex-start;
        }

        .customer-detail {
            display: flex;
            flex-direction: column;

            label{
                // No text wrap
                white-space: nowrap;
            }

            h3 {
                align-self: flex-start;
            }

            .input-text-box {
                display: flex;
                align-self: flex-start;
                width: 100%;
                margin: 10px 0px;

                label {
                    // font-size: $text-font-size-regular;
                }

                .input-text {
                    margin-left: 10px;
                    // font-size: $text-font-size-regular;
                    width: 100%;
                }

                .phonenumber {
                    width: 300px;
                }
            }
        }
    }
}