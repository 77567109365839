@import '../../../../../../globalcss.scss';

.self-quotation-create-sub-container-product-form {
    .content-p2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .product-list {
            display: flex;
            flex-direction: column-reverse;
            width: 100%;

            .input-product-name {
                width: 100%;
                font-size: 20px;
                margin-bottom: 15px;
            }

            .productTemplate {
                margin: 10px 0px;
            }
        }

        .add-icon {
            font-size: 30px;
            color: $mainColor;
            cursor: pointer;
            transition: all $mainAnimationTime ease-in-out;

            &:hover {
                color: $mainColorHover;
            }
        }

        .shipping-section {
            margin-top: 15px;
            display: flex;

            .shipping-label1 {
                margin-right: 10px;
            }

        }
    }


}


.custom-class-000002 .product-img{
    width: 50px;
    height: 50px;
    transition: all .2s ease-in-out;
    &:hover {
        transform: scale(5);
    }
}

.custom-class-000002 .custom-class-000001{
    align-self: center;
    height: 100%;
    margin-left: 10px;
}

.custom-class-000002 {
    display: flex;
}