.blinking-under-line-number {
    .number {
        display: inline-block;
    }

    .blinking {
        animation: blink 2s ease-in-out infinite;
    }

    @keyframes blink {
        0% {
            opacity: 1;
        }

        49% {
            opacity: 1;
        }

        50% {
            opacity: 0.2;
        }

        100% {
            opacity: 1;
        }
    }
}