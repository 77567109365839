@import '../../../globalcss.scss';

.always-lowest-prices {
    .content {
        margin: $DEFAULT_CONTENT_MARGIN;
        .title {
            h2 {
                margin-bottom: 0px;
            }
            h3 {
                margin-top:0px;
            }
        }
        .body {
            .information-section {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items:flex-start;
                .block1 {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 10px;
                    align-items: flex-start;
                    * {
                        align-self: flex-start;
                    }
                    .block1-body {
                        text-align: left;
                    }

                }
            }
        }

    }
}