$FAST_ANIMATION : all 0.1s ease;

.button-hover-style {
    cursor: pointer;

    &:hover {
        transform: scale(1.1);
        transition: $FAST_ANIMATION;

    }
}