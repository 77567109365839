.pages-self-quotation-sub-page-self-quotation-create {

    .self-quotation-create-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .self-quotation-create-content-wrapper {
            width: 80%;

            .self-quotation-actions {
                // Right
                display: flex;
                justify-content: flex-end;
                width: 100%;
                margin-top: 20px;
            }
        }

    }
}