.contact {
    .contact-content{
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            width: 300px;
            height: 300px;
        }
        .line-icon{
            margin-right: 10px;
            width: 40px;
            height: 40px;
        }
        .link {
            // Remove default link style
            color: inherit;
            text-decoration: none;
            &:hover {
                color: inherit;
                text-decoration: none;
            }
        }
    }
}