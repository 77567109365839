@import '../../globalcss.scss';

.flex-table {
    display: flex;
    height: 100%;
    flex-direction: column;
    min-height: 0px;
    min-width: 800px;

    
    .header {
        display: flex;
        margin-top: 40px;
        box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 1.0);
        padding: 0 20px;
        
    }

    .body {
        display: flex;
        flex-direction: column;
        min-height: 0;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 0 20px;
        padding-top: 20px;


        // Hide the scrollbar
        &::-webkit-scrollbar {
            display: none;
        }

        .row {
            display: flex;
            margin-bottom: 20px;
            align-items: center;
            transition: all $mainAnimationTime;

            &:hover {
                // transform: scale(1.01);
                box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
                // Round the corner
                border-radius: 10px;
            }
        }
    }
}