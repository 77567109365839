@import '../../globalcss.scss';
.main{
    height: calc(100vh - $NAVBAR_HEIGHT);
    
    .main-content {
        width: 100%;
        display: flex;
        // align-items: center;
        justify-content: center;
        font-weight: bold;

        .title {
            margin-top: 50px;
        }
    }
}